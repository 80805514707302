/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(function(){

  if (!$('html').hasClass("lt-ie9")) {
      (function(){var b,f;b=this.jQuery||window.jQuery;f=b(window);b.fn.stick_in_parent=function(d){var A,w,J,n,B,K,p,q,k,E,t;null==d&&(d={});t=d.sticky_class;B=d.inner_scrolling;E=d.recalc_every;k=d.parent;q=d.offset_top;p=d.spacer;w=d.bottoming;null==q&&(q=0);null==k&&(k=void 0);null==B&&(B=!0);null==t&&(t="is_stuck");A=b(document);null==w&&(w=!0);J=function(a,d,n,C,F,u,r,G){var v,H,m,D,I,c,g,x,y,z,h,l;if(!a.data("sticky_kit")){a.data("sticky_kit",!0);I=A.height();g=a.parent();null!=k&&(g=g.closest(k));
      if(!g.length)throw"failed to find stick parent";v=m=!1;(h=null!=p?p&&a.closest(p):b("<div />"))&&h.css("position",a.css("position"));x=function(){var c,f,e;if(!G&&(I=A.height(),c=parseInt(g.css("border-top-width"),10),f=parseInt(g.css("padding-top"),10),d=parseInt(g.css("padding-bottom"),10),n=g.offset().top+c+f,C=g.height(),m&&(v=m=!1,null==p&&(a.insertAfter(h),h.detach()),a.css({position:"",top:"",width:"",bottom:""}).removeClass(t),e=!0),F=a.offset().top-(parseInt(a.css("margin-top"),10)||0)-q,
      u=a.outerHeight(!0),r=a.css("float"),h&&h.css({width:a.outerWidth(!0),height:u,display:a.css("display"),"vertical-align":a.css("vertical-align"),"float":r}),e))return l()};x();if(u!==C)return D=void 0,c=q,z=E,l=function(){var b,l,e,k;if(!G&&(e=!1,null!=z&&(--z,0>=z&&(z=E,x(),e=!0)),e||A.height()===I||x(),e=f.scrollTop(),null!=D&&(l=e-D),D=e,m?(w&&(k=e+u+c>C+n,v&&!k&&(v=!1,a.css({position:"fixed",bottom:"",top:c}).trigger("sticky_kit:unbottom"))),e<F&&(m=!1,c=q,null==p&&("left"!==r&&"right"!==r||a.insertAfter(h),
      h.detach()),b={position:"",width:"",top:""},a.css(b).removeClass(t).trigger("sticky_kit:unstick")),B&&(b=f.height(),u+q>b&&!v&&(c-=l,c=Math.max(b-u,c),c=Math.min(q,c),m&&a.css({top:c+"px"})))):e>F&&(m=!0,b={position:"fixed",top:c},b.width="border-box"===a.css("box-sizing")?a.outerWidth()+"px":a.width()+"px",a.css(b).addClass(t),null==p&&(a.after(h),"left"!==r&&"right"!==r||h.append(a)),a.trigger("sticky_kit:stick")),m&&w&&(null==k&&(k=e+u+c>C+n),!v&&k)))return v=!0,"static"===g.css("position")&&g.css({position:"relative"}),
      a.css({position:"absolute",bottom:d,top:"auto"}).trigger("sticky_kit:bottom")},y=function(){x();return l()},H=function(){G=!0;f.off("touchmove",l);f.off("scroll",l);f.off("resize",y);b(document.body).off("sticky_kit:recalc",y);a.off("sticky_kit:detach",H);a.removeData("sticky_kit");a.css({position:"",bottom:"",top:"",width:""});g.position("position","");if(m)return null==p&&("left"!==r&&"right"!==r||a.insertAfter(h),h.remove()),a.removeClass(t)},f.on("touchmove",l),f.on("scroll",l),f.on("resize",
      y),b(document.body).on("sticky_kit:recalc",y),a.on("sticky_kit:detach",H),setTimeout(l,0)}};n=0;for(K=this.length;n<K;n++)d=this[n],J(b(d));return this}}).call(this);

  }

  });

  function setMegasubmenu() {
      var t = $("#navbar>.navbar-nav").width();
      $(window).innerWidth() >= 1200 && $(".megasubmenu").css("width", t + "px")
  }
  $(window).load(function(){setMegasubmenu()});
  $.fn.eqHeights = function(t) {
      var e = {
              child: !1,
              parentSelector: null
          },
          t = $.extend(e, t),
          o = $(this);
      if (o.length > 0 && !o.data("eqHeights") && ($(window).bind("resize.eqHeights", function() {
              o.eqHeights()
          }), o.data("eqHeights", !0)), t.child && t.child.length > 0) var n = $(t.child, this);
      else var n = $(this).children();
      var i, a = 0,
          s = 0,
          c = [];
      n.height("auto").each(function() {
          t.parentSelector && i !== $(this).parents(t.parentSelector).get(0) && ($(c).height(s), s = 0, a = 0, c = [], i = $(this).parents(t.parentSelector).get(0));
          var e = this.offsetTop;
          a > 0 && a != e && ($(c).height(s), s = $(this).height(), c = []), s = Math.max(s, $(this).height()), a = this.offsetTop, c.push(this)
      }), $(c).height(s)
   }, $(document).ready(function() {

      if (!$('html').hasClass("lt-ie9")) {
          $(window).width() > 991 && $('.sticky').stick_in_parent(), setMegasubmenu(), $(document).on("click", function() {
              $(".form-search").removeClass("open")
          }), $(".icon-search").on("click", function(t) {
              /*t.preventDefault(), t.stopPropagation(),*/ $(".form-search").toggleClass("open")
          }), $(".form-search").on("click", function(t) {
              t.stopPropagation()
          }), $("#back-to-top").on("click", function(t) {
              t.preventDefault(), $("html,body").animate({
                  scrollTop: 0
              }, 700)
          }), $(".scroll-down").on("click", function(t) {
              t.preventDefault(), $("html,body").animate({
                  scrollTop: 440
              }, 700)
          }), $(window).on("resize", function() {
              if($(window).width() > 991){$('.sticky').stick_in_parent()}else{$('.sticky').stick_in_parent("sticky_kit:detach")} setMegasubmenu(), $(".sticky").css("margin-top", "0")
          })

      }
      else{
          $(window).width() > 991, setMegasubmenu(), $(document).on("click", function() {
              $(".form-search").removeClass("open")
          }), $(".icon-search").on("click", function(t) {
              t.preventDefault(), t.stopPropagation(), $(".form-search").toggleClass("open")
          }), $(".form-search").on("click", function(t) {
              t.stopPropagation()
          }), $("#back-to-top").on("click", function(t) {
              t.preventDefault(), $("html,body").animate({
                  scrollTop: 0
              }, 700)
          }), $(".scroll-down").on("click", function(t) {
              t.preventDefault(), $("html,body").animate({
                  scrollTop: 440
              }, 700)
          })
      }


  }), 
  $(document).on("click", ".footer-navigation-list h3 a", function(t) {
      t.preventDefault(), $(".footer-navigation-list h3 a").parent().toggleClass("footer-active"), $(".footer-menu-toggled").slideToggle()
  });

  $(window).load(function() {
    $('.test > div').eqHeights({parentSelector:'.test'});
  }); 
  $(document).on('ready',function(){
    $(document).on('click','.account-nav li a',function(){
      $('.booking-list > .box').eqHeights({parentSelector:'.booking-list'});
    });
    $('.big-slider').slick({
      dots: true,
      arrows:true,
      responsive: [
      {
          breakpoint: 767,
          settings: {
            arrows: false
          }
        }
        ]
    });
    $('.category-slider').slick({
      dots: true,
      arrows:true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots:false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false,
            dots:false
          }
        }
      ]
    });
    $('.article-slider').slick({
        dots:true,
        responsive: [{
          breakpoint: 767,
          settings: {
            arrows: false,
          }
        }]
    });
    $('.articles').slick({
        dots: false,
        arrows:true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendArrows:'.articles-nav',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots:false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots:false
            }
          }
        ]
    });
  });





})(jQuery); // Fully reference jQuery after this point.
